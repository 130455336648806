import React, { useEffect, useState } from "react";
import { PAGE_ROUTE, API_URL } from '../shared/constant';
import axios from 'axios';
import Select from 'react-select';
import swal from 'sweetalert';
import { Alert } from 'react-bootstrap';
import Crop from '../shared/crop';
import { Form, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoaderComponent from '../../admin/shared/loader';
import { MdCancel } from 'react-icons/md';


const ImageForm = () => {
    const [product, setProduct] = useState([])
    const [imagePreview, setImagePreview] = useState('');
    let { id } = useParams();
    const navigate = useNavigate();
    const [error, setError] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        image: '',
        product_id: '',
    });


    const [images, setImage] = useState([])

    const getImage = (id) => {
        axios.post(API_URL.ADMIN_PRODUCT_GET + id).then(res => {
            setImage(res.data.data.images)
        })
    }
    useEffect(() => {
        id && getImage();
    }, [id])


    const [name, setName] = useState([{
        value: "",
        label: ""
    }]);

    const namedata = () => {
        product.map((e) => {
            setName((name) => [
                ...name, {
                    value: e.id,
                    label: e.name + ' / ' + e.category_name
                }
            ])
        })
    }

    const getProductDrpData = () => {
        axios.post(API_URL.ADMIN_PRODUCT_LIST, {}).then(res => {
            setProduct(res.data.data);

        })
    }

    useEffect(() => {
        getProductDrpData();
    }, []);

    useEffect(() => {
        namedata();
    }, [product]);

    const onSubmit = (e) => {
        e.preventDefault();
        if (isInvalid(formData.image)) {
            toast.error("Image Is Required");
            return false;
        }
        setError([]);
        setLoading(true);
        let url = API_URL.ADMIN_PRODUCT_IMAGE_ADD;
        let body = formData;

        axios.post(url, body).then((response) => {
            setLoading(false);
            if (response.data.status === 1) {
                toast.success('Image Created Successfully', { autoClose: 3000 });
                setTimeout(() => {
                    navigate(PAGE_ROUTE.ADMIN_IMAGE_LIST, { replace: true });
                }, 100); getImage(formData.product_id);
            } else {
                if (response.data && response.data.data) {
                    let errors = [];
                    Object.keys(response.data.data).forEach((key) => {
                        errors.push(response.data.data[key][0]);
                    });
                    setError(errors);
                    toast.error('Something went wrong..', { autoClose: 3000 })
                }
            }
        }, (error) => {
            setLoading(false);
            toast.error('Something went wrong..', { autoClose: 3000 })
        });
    }

    const isInvalid = (value) => {
        return (!value || value === '' || value == null);
    };

    const changeHandler = e => {
        setFormData({ ...formData, product_id: e.value })
        getImage(e.value)
    };
    const onDeleteClick = (id, pid) => {
        swal({
            title: "Are you sure want to delete?",
            icon: "warning",
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            buttons: true
        }).then((willDelete) => {
            if (willDelete) {
                onDeleteimage && onDeleteimage(id, pid);
            }
        });
    }

    const onDeleteimage = (id, pid) => {
        axios.post(API_URL.ADMIN_PRODUCT_IMAGE_DELETE, { id: id, product_id: pid }).then((response) => {
            id && getImage(pid);
        });
    };


    return (
        <div className="card">
            <div className="card-body">
                <div className="card-title">Add Product Image</div>
                {error.length > 0 &&
                    <>
                        {[
                            'danger',
                        ].map((variant) => (
                            <Alert key={variant} variant={variant}>
                                {error.map((element, key) => {
                                    return (
                                        <div key={key}>{element}</div>
                                    )
                                })}
                            </Alert>
                        ))}
                    </>
                }
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className='col-md-6'>
                            <div className="form-group">
                                <label htmlFor="Category">Products</label>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    options={name}
                                    value={name.value}
                                    onChange={changeHandler}
                                >
                                </Select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label>Preview</label>
                            <div className='d-flex'>
                                {
                                    images.map((ctr, ke) => {
                                        return <div style={{ position: 'relative', display: 'inline-flex' }} key={ke}><img className="ms-2" src={ctr.image} height={100} width={100} alt="category_image" />  {images.length !== 1 ? <MdCancel onClick={() => onDeleteClick(ctr.id, ctr.product_id)} style={{ top: 0, right: 0, position: 'absolute', color: 'red' }} /> : ''} </div>
                                    })
                                }
                            </div>
                        </div>
                        <div className='col-md-12 mt-2'>
                            <div className='form-group'>
                                <label>Image</label>
                                <Crop imagePreview={imagePreview} cropShape='round' guid={''}
                                    onChangeCropper={async ({ fileCropped, fileCroppedBase64 }) => {
                                        setFormData({ ...formData, image: fileCroppedBase64 })
                                        setImagePreview(fileCroppedBase64);
                                        //   await uploadFile(fileCropped)
                                    }} />
                            </div>
                        </div>
                        <div className='text-end'>
                            {
                                isLoading && <LoaderComponent type={'watch'} height={35} width={35}></LoaderComponent>
                            }
                            <button type="submit" className="btn btn-primary">Create</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ImageForm