import { React, useEffect, useState } from 'react';
import { PAGE_ROUTE, API_URL } from '../shared/constant';
import { NavLink, useNavigate } from 'react-router-dom';
import TableComponent from '../../admin/shared/table';
import LoaderComponent from '../../admin/shared/loader';
import { Form } from 'react-bootstrap';
import axios from 'axios';

export default function ProductListComponent() {

    const columns = [
        {
            field: 'image',
            lable: 'Image'
        },
        {
            field: 'name',
            lable: 'Name'
        }, {
            field: 'category_name',
            lable: 'Category'
        },];

    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [dataCopy, setDataCopy] = useState([]);
    const [categoryDrp, setCategoryDrpData] = useState([]);
    const [subCategoryDrp, setSubCategoryDrp] = useState([]);
    const [isRecall, setIsRecall] = useState(false);
    const [formData, setFormData] = useState({
        category_id: '',
        sub_category_id: ''
    });

    useEffect(() => {
        getProduct();
    }, []);

    useEffect(() => {
        isRecall && getProduct();
    }, [isRecall]);

    const getProduct = () => {
        setLoading(true);
        axios.post(API_URL.ADMIN_PRODUCT_LIST, {}).then(res => {
            setData(res.data.data);
            setDataCopy(res.data.data);
            setIsRecall(false);
            setLoading(false);
            getCategoryDrpData();
        }, error => {
            setLoading(false);
        })
    }; 

    const getCategoryDrpData = () => {
        axios.post(API_URL.ADMIN_CATEGORY_LIST, {}).then(res => {
            setCategoryDrpData(res.data.data)
        })
    };

    const getSubCategoryDrpData = (value) => {
        axios.post(API_URL.ADMIN_SUB_CATEGORY_BY_CATEGORY, { category_id: value }).then(res => {
            setSubCategoryDrp(res.data.data)
        })
    };

    const filterData = (category_id, sub_category_id) => {
        let cData = dataCopy.filter(row => (row.category_id == category_id));
        if (sub_category_id) {
            cData = dataCopy.filter(row => (row.category_id == category_id && row.sub_category_id == sub_category_id));
        }
        setData(cData);
    };


    const onDelete = (item) => {
        let id = item.id;
        axios.post(API_URL.ADMIN_PRODUCT_DELETE + id).then((response) => {
            setIsRecall(true);
        });
    };

    const onEdit = (item) => {
        navigate(PAGE_ROUTE.ADMIN_PRODUCT_EDIT + item.id, { replace: true });
    };

    const [loginId, setloginId] = useState('')

    const getLoginId = () => {
        let lid = sessionStorage.getItem('login_id');
        setloginId(lid)
    }

    useEffect(() => {
        getLoginId();
    }, [])


    return (
        <div>
            <div className='card'>
                <div className='card-body'>
                    <h4 className='card-title'>Products
                        <NavLink to={PAGE_ROUTE.ADMIN_PRODUCT_CREATE} className="btn btn-primary">
                            Add
                        </NavLink>
                    </h4>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='form-group'>
                                <label>Category</label>
                                <Form.Select aria-label="Category" value={formData.category_id} onChange={(event) => {
                                    getSubCategoryDrpData(event.target.value);
                                    filterData(event.target.value, null);
                                    setFormData({ ...formData, category_id: event.target.value })
                                }}>
                                    value={formData.category_id}
                                    <option value="">Select</option>
                                    {
                                        categoryDrp.map((res, key) => {
                                            return (
                                                <option key={key} value={res.id}>{res.name}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </div>
                        </div>
                        {
                            (loginId != 3) &&
                            <div className='col-md-4'>
                                <div className="form-group">
                                    <label htmlFor="Sub Category">Sub Category</label>
                                    {/* <span className='start'>*</span> */}
                                    <Form.Select
                                        aria-label="Sub Category"
                                        value={formData.sub_category_id}
                                        disabled={!formData.category_id}
                                        onChange={(event) => {
                                            setFormData({ ...formData, sub_category_id: event.target.value })
                                            filterData(formData.category_id, event.target.value);
                                        }}>
                                        value={formData.sub_category_id}
                                        <option value="">Select</option>
                                        {
                                            subCategoryDrp.map((res, key) => {
                                                return (
                                                    <option key={key} value={res.id}>{res.name}</option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                </div>
                            </div>
                        }
                    </div>

                    {
                        isLoading && <LoaderComponent height={80} width={80}></LoaderComponent>
                    }
                    {
                        !isLoading && <div className="table-responsive">
                            <div className='table-responsive mt-3'>
                                <TableComponent
                                    columns={columns}
                                    data={data}
                                    onDelete={onDelete}
                                    onEdit={onEdit}>
                                </TableComponent>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
