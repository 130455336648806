import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import { PAGE_ROUTE } from '../../admin/shared/constant';
import Background from '../assets/image/Greenora homes.png';

const Header = () => {
    const companyName = sessionStorage.getItem('company');
    const navigate = useNavigate();
    return (
        <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
            <div className="navbar-brand-wrapper d-flex justify-content-center" style={{backgroundColor:'black'}}>
                <div className="navbar-brand-inner-wrapper d-flex justify-content-between align-items-center w-100">
                    <Link to={PAGE_ROUTE.ADMIN_DASHBOARD} className="navbar-brand brand-logo">
                        <img src={Background} style={{ width: '100%' }} alt='Greenora_logo' />
                    </Link>
                    <Link to={PAGE_ROUTE.ADMIN_DASHBOARD} className="navbar-brand brand-logo-mini">MY LOGO</Link>
                    <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
                        <span className="mdi mdi-sort-variant"></span>
                    </button>
                </div>
            </div>
            <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
                <ul className="navbar-nav navbar-nav-right">
                    <li className='nav-item'>
                        <button type="button" onClick={() => {
                            sessionStorage.clear();
                            navigate('/login', { replace: true });
                        }} className="btn btn-danger btn-fw">Logout</button>
                    </li>
                </ul>
                <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
                    <span className="mdi mdi-menu"></span>
                </button>
            </div>
        </nav>
    )
}

export default Header;